import { AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import { batch } from 'react-redux';

import { CatalogsActions, TCatalogProduct } from './catalog';
import { PaymentActions } from './payment';
import NetworkStatus from '../../utils/enums/NetworkStatus';
import { TAction } from '../store';

export const Types = {
    SET_CELL: 'AQUAMARKET@SET:CELL',
    SET_RETURNABLE_CONTAINER: 'AQUAMARKET@SET:RETURNABLE:CONTAINER',
    SET_IS_SBP_PAYMENT: 'AQUAMARKET@SET:IS:SBP:PAYMENT',
    SET_NETWORK_STATUS: 'AQUAMARKET@SET:NETWORK:STATUS',
};

type TResponseBase = {
    '@id': string;
    '@type': string;
};

export type TProduct = {
    id: string;
    monolithId: number;
    name: string;
    type: string;
};

type TAdditionalProduct = {
    '@type': string;
    quantity: number;
    product: TResponseBase & {
        createdAt: string;
        id: string;
        monolithId: number;
        name: string;
        partner: string;
        type: string;
        updatedAt: string;
    };
};

type TProductInSet = TResponseBase & {
    product: TProduct;
    productPrice: number;
    productQuantity: number;
    productTotal: number;
};

type TActiveSet = TResponseBase & {
    insufficientBottlesToReturn?: number;
    productsInSet: TProductInSet[];
};

type TBox = TResponseBase & {
    active: boolean;
};

type TCreateOrder = {
    sourceSite: string;
    cell: string;
    additionalProducts?: {
        product: string;
        quantity: number;
    }[];
};

export type TOrderResponse = TResponseBase & {
    '@context': string;
    additionalProducts: TAdditionalProduct[];
    cell: string;
    result: {
        '@type': string;
        orderDto: any;
        paymentMethodId: string;
    };
    externalOrder: any;
};

export type TCell = {
    '@context': string;
    '@id': string;
    '@type': string;
    activeSet: TActiveSet;
    enabled: boolean;
    box: TBox;
};

export type TReturnableContainer = TCatalogProduct & {
    uuid: string;
};

type TSetCell = {
    type: typeof Types.SET_CELL;
    payload: TCell;
};

type TSetReturnableContainer = {
    type: typeof Types.SET_RETURNABLE_CONTAINER;
    payload: TReturnableContainer | null;
};

type TSetIsSbpPayment = {
    type: typeof Types.SET_IS_SBP_PAYMENT;
    payload: boolean;
};

type TSetNetworkStatus = {
    type: typeof Types.SET_NETWORK_STATUS;
    payload: NetworkStatus;
};

export type TAquamarketActions = TSetCell;

type AquamarketActionsType = {
    fetchCell: (uuid: string) => TAction<Promise<void>>;
    fetchReturnableContainer: () => TAction<Promise<void>>;
    createOrder: (
        cell: string,
        returnedBottles: number | undefined,
    ) => TAction<Promise<void | AxiosResponse<any>>>;
    payForOrder: (paymentUrl: string, paymentMethodId: string) => TAction<Promise<void>>;
    setCell: (cell: TCell) => TSetCell;
    setNetworkStatus: (status: NetworkStatus) => TSetNetworkStatus;
    setReturnableContainer: (product: TReturnableContainer | null) => TSetReturnableContainer;
    setIsSbpPayment: (isSbp: boolean) => TSetIsSbpPayment;
};

export const AquamarketActions: AquamarketActionsType = {
    fetchCell(uuid) {
        return async (dispatch, _, { httpClientServices }) => {
            dispatch(this.setNetworkStatus(NetworkStatus.loading));
            const instance = httpClientServices.getClient();

            axiosRetry(instance, {
                retries: 3,
                retryCondition: () => true,
                retryDelay: () => 2000,
            });

            instance
                .get<TCell>(`https://aquamarket.service.appsol.ru/aquamarket/cells/${uuid}`)
                .then(({ data }) => {
                    batch(() => {
                        dispatch(this.setCell(data));
                    });
                })
                .catch(() => {})
                .finally(() => {
                    dispatch(this.setNetworkStatus(NetworkStatus.ready));
                });
        };
    },
    fetchReturnableContainer() {
        return async (dispatch, _, { httpClientServices }) => {
            httpClientServices
                .getClient()
                .get<TProduct[]>(
                    'https://aquamarket.service.appsol.ru/aquamarket/products.json?type=RETURNABLE_CONTAINER',
                )
                .then(({ data }) => {
                    const returnableContainer = data[0];
                    if (returnableContainer) {
                        const productInCatalog = dispatch(CatalogsActions.getProduct(2));
                        if (productInCatalog) {
                            const product: TReturnableContainer = {
                                ...productInCatalog,
                                uuid: returnableContainer.id,
                            };
                            dispatch(this.setReturnableContainer(product));
                        }
                    }
                });
        };
    },
    createOrder(cell, returnedBottles) {
        return async (_, getState, { httpClientServices }) => {
            const data: TCreateOrder = {
                cell,
                sourceSite: window.location.origin,
            };
            const { returnableContainer } = getState().aquamarket;
            if (returnedBottles) {
                data.additionalProducts = [
                    {
                        product: `/aquamarket/products/${returnableContainer?.uuid}`,
                        quantity: returnedBottles,
                    },
                ];
            }
            const url = 'https://aquamarket.service.appsol.ru/aquamarket/orders';
            return httpClientServices
                .getClient()
                .post<TOrderResponse>(url, data, {
                    headers: {
                        'X-Source': 'aquamarket',
                        'device-info': JSON.stringify({
                            uniqueDeviceId: localStorage.getItem('device-id'),
                        }),
                    },
                })
                .then(res => res);
        };
    },
    payForOrder(paymentUrl, paymentMethodId) {
        return async (dispatch, getState) => {
            const { payments } = getState().order;
            const sbpPaymentMethodId = payments.find(payment => payment.setup.sbp === true)?.[
                '@id'
            ];
            if (sbpPaymentMethodId && paymentMethodId === sbpPaymentMethodId) {
                dispatch(this.setIsSbpPayment(true));
            }

            dispatch(PaymentActions.setPaymentUrl(paymentUrl));
        };
    },
    setCell(cell) {
        return {
            type: Types.SET_CELL,
            payload: cell,
        };
    },
    setReturnableContainer(product) {
        return {
            type: Types.SET_RETURNABLE_CONTAINER,
            payload: product,
        };
    },
    setIsSbpPayment(isSbp) {
        return {
            type: Types.SET_IS_SBP_PAYMENT,
            payload: isSbp,
        };
    },
    setNetworkStatus(status) {
        return {
            type: Types.SET_NETWORK_STATUS,
            payload: status,
        };
    },
};
