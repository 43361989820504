import { message } from 'antd';

import { TUserData } from './user';
import TokenHelper from '../../utils/token';
import { TAction } from '../store';

type TEnrichToken = {
    '@context': string;
    '@id': string;
    '@type': string;
    id: number;
    clientId: string | null;
    token: string;
};

type ClientTypes = {
    clientEnrichToken: () => TAction<Promise<void>>;
};

export const ClientActions: ClientTypes = {
    clientEnrichToken() {
        return async (_, getState, { httpClientServices, services }) => {
            const { data } = getState().user;
            const { selectedAddress } = getState().addresses;
            const { info } = getState().company;

            const isAuth = data !== null && TokenHelper.isAnon();

            if (isAuth && selectedAddress && info) {
                const { uuid, phone, username, email } = data as TUserData;
                const body = {
                    externalUserId: uuid,
                    externalLocationId: selectedAddress.external_id ?? null,
                    username: email,
                    phone: `${info.phone_code}${phone}`,
                    firstName: username,
                };

                return httpClientServices
                    .getClient('client')
                    .post<TEnrichToken>('/client/enrich_token_requests', body)
                    .then(({ data }) => {
                        return TokenHelper.setTokens({ token: data.token });
                    })
                    .catch(e => {
                        if (e.response && e.response.status !== 404) {
                            message.error(e.message);
                        }
                        TokenHelper.cleanTokens();
                    });
            }
        };
    },
};
