import React from 'react';

import { useDispatch } from 'react-redux';

import { useTypedSelector } from './useTypedSelectors';
import { CompanyActions } from '../redux/actions/company';

const useCompany = () => {
    const company = useTypedSelector(({ company }) => company);

    const dispatch = useDispatch();

    const defaultLanguage = React.useMemo(
        () => company.info?.languages.find(language => language.is_default === '1'),
        [company.info],
    );

    const isAppUrls = !(!company.info?.google_play_app_url || !company.info?.app_store_app_url);

    const fetchCompanyInfo = React.useCallback(() => {
        return Promise.all([dispatch(CompanyActions.fetchCompanyInfo())]);
    }, [dispatch]);

    const fetchCompanyLogo = React.useCallback(() => {
        return dispatch(CompanyActions.fetchCompanyLogo());
    }, [dispatch]);

    const fetchCompanyMockup = React.useCallback(() => {
        return dispatch(CompanyActions.fetchCompanyMockup());
    }, [dispatch]);

    const fetchCompanyBanners = React.useCallback(() => {
        return dispatch(CompanyActions.fetchCompanyBanners());
    }, [dispatch]);

    return {
        companyInfo: company.info,
        companyLogo: company.logo,
        companyMockup: company.mockup,
        companyBanners: company.banners,
        fetchCompanyInfo,
        fetchCompanyLogo,
        fetchCompanyMockup,
        fetchCompanyBanners,
        networkStatus: company.networkStatus,
        bannersNetworkStatus: company.bannersNetworkStatus,
        defaultLanguage,
        isAppUrls,
    };
};

export default useCompany;
