import NetworkStatus from '../../utils/enums/NetworkStatus';
import { TAquamarketActions, TCell, TReturnableContainer, Types } from '../actions/aquamarket';

export declare type AquamarketState = {
    cell: TCell | null;
    returnableContainer: TReturnableContainer | null;
    isSbpPayment: boolean;
    networkStatus: NetworkStatus;
};

const initState: AquamarketState = {
    cell: null,
    returnableContainer: null,
    isSbpPayment: false,
    networkStatus: NetworkStatus.pending,
};

const aquamarket = (state = initState, action: TAquamarketActions): AquamarketState => {
    let newState;
    switch (action.type) {
        case Types.SET_CELL:
            newState = { ...state, cell: action.payload };
            break;
        case Types.SET_RETURNABLE_CONTAINER:
            newState = { ...state, returnableContainer: action.payload };
            break;
        case Types.SET_IS_SBP_PAYMENT:
            newState = { ...state, isSbpPayment: action.payload };
            break;
        case Types.SET_NETWORK_STATUS:
            newState = { ...state, networkStatus: action.payload };
            break;
    }

    return (newState as any) || state;
};

export default aquamarket;
