import React from 'react';

import { Button, Typography } from 'antd';

import useCompany from '../../../../hooks/useCompany';
import CloseSVG from '../../assets/close.svg';
import ErrorSVG from '../../assets/error.svg';
import SuccessSVG from '../../assets/success.svg';
import WaitingSVG from '../../assets/waiting.svg';

import './styles.less';

type StatusType = {
    title: string;
    subtitle: string;
    additional?: string;
    icon: JSX.Element;
};

const statuses = {
    waiting: {
        title: 'Немного терпения',
        subtitle: 'На обработку платежа требуется до 1 минуты',
        icon: <WaitingSVG />,
    },
    success: {
        title: 'Спасибо!',
        subtitle: 'Ваша покупка успешно оплачена',
        additional: 'Дверца ячейки откроется в течение одной минуты',
        icon: <SuccessSVG />,
    },
    error: {
        title: 'Ваш платеж не прошел',
        subtitle: 'Возможно это временный сбой. Попробуйте снова.',
        icon: <ErrorSVG />,
    },
};

export const Status: React.FC = () => {
    const [status, setStatus] = React.useState<StatusType>(statuses.success);

    const { companyInfo, fetchCompanyInfo } = useCompany();

    React.useEffect(() => {
        fetchCompanyInfo();
    }, [fetchCompanyInfo]);

    return (
        <div className="status-screen">
            <div className="status-screen__top">
                <Button href="/aquamarket" type="text">
                    <CloseSVG />
                </Button>
            </div>
            <Typography.Title level={4}>{status.title}</Typography.Title>
            <Typography.Text>{status.subtitle}</Typography.Text>
            {status?.additional && (
                <Typography.Text className="status-screen__additional">
                    {status?.additional}
                </Typography.Text>
            )}
            <div className="status-screen__icon">{status.icon}</div>
            <Typography.Text className="status-screen__support">
                Если ячейка не открылась, напишите в техподдержку или позвоните{' '}
                <a href={`tel: ${companyInfo?.phone}`}>{companyInfo?.phone}</a>
            </Typography.Text>
        </div>
    );
};
