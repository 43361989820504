export enum NavigateRoutes {
    Main = '/',
    Product = '/products*',
    ProductSet = '/product_sets*',
    Checkout = '/checkout',
    AppRedirect = '/get*',
    Cart = '/cart',
    Profile = '/profile',
    About = '/about',
    Addresses = '/addresses',
    Orders = '/orders',
    Order = '/orders*',
    Bonus = '/bonus',
}

export const modalRoutes = [
    NavigateRoutes.Cart,
    NavigateRoutes.Profile,
    NavigateRoutes.About,
    NavigateRoutes.Addresses,
    NavigateRoutes.Orders,
    NavigateRoutes.Order,
    NavigateRoutes.Bonus,
    NavigateRoutes.Product,
    NavigateRoutes.ProductSet,
];
